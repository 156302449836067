import { Observable, Subscription, timer } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export class CountDownTimer {
  private subscription!: Subscription;

  count!: number;
  constructor(private duration: number) {
    this.count = duration;
  }
  observable: Observable<number> = timer(1000, 1000).pipe(
    map((i) => {
      return this.duration - i;
    }),
    tap((i) => {
      this.count = i;
      if (i === 0) {
        this.stopTimer();
      }
    })
  );

  private resetCount() {
    this.count = this.duration;
  }
  stopTimer() {
    this.subscription?.unsubscribe();
  }
  startTimer() {
    this.resetTimer();
    this.subscription = this.observable.subscribe();
  }
  resetTimer() {
    this.resetCount();
    this.stopTimer();
  }
}
