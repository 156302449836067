<div *ngIf="selected && selected.stepLabel" [ngTemplateOutlet]="selected ? selected.stepLabel.template : null"></div>
<div class="container">
  <div class="steps">
    <mat-nav-list>
      <mat-list-item *ngFor="let step of steps; let i = index" (click)="selectStepByIndex(i)" [ngClass]="{ active: selectedIndex === i }">
        <a matLine>{{ step.label }}</a>
        <mat-divider></mat-divider>
      </mat-list-item>
    </mat-nav-list>
  </div>
  <div class="content">
    <div [ngTemplateOutlet]="selected ? selected.content : null"></div>
  </div>
</div>
