import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InvitationsService } from 'src/app/invitations/invitations.service';
import { catchErrorToFalse } from '../utils/operators.rxjs';

@Injectable({
  providedIn: 'root',
})
export class InvitationExistsGuard implements CanActivate {
  constructor(private router: Router, private invitationsService: InvitationsService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const id = route.params.id;
    return this.invitationsService.invitationExists(id).pipe(
      catchErrorToFalse,
      map((exists) => {
        return exists ? true : this.router.createUrlTree(['/']);
      })
    );
  }
}
